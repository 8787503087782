import React from 'react'
import src1 from '../../images/screen1.webp'
import src2 from '../../images/screen2.webp'
import src3 from '../../images/screen3.webp'
import src4 from '../../images/screen4.webp'
import src5 from '../../images/screen5.webp'
import src6 from '../../images/screenhand.webp'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';

const Carousal = () => {
    const pics = [src1, src2, src3, src4, src5, src1];
    return (
        <div className='screenshot-section'>
            <div className='screenshots-slider'>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={10}
                    breakpoints={{
                        640: {
                          slidesPerView: 3,
                          spaceBetween: 20,
                        },
                        768: {
                          slidesPerView: 4,
                          spaceBetween: 40,
                        },
                        1024: {
                          slidesPerView: 5,
                          spaceBetween: 50,
                        },
                      }}
                    autoplay={{
                        delay: 1500,
                        disableOnInteraction: false,
                    }}
                    speed={1500} 
                    cssMode={true} 
                    loop={true}
                    modules={[Autoplay]}
                    className="mySwiper"
                >
                    {pics.map((ele, i) => {
                        return (
                            <SwiperSlide>
                                <div className='img' key={i}>
                                    <img src={ele} alt={ele}></img>
                                </div>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </div>
            <img src={src6} alt="screen-hand" className='screen-hand'></img>
        </div>
    )
}

export default Carousal
