import React from 'react'
import * as common from '../common'

const DesktopLayout = ({children}) => {
  return (
    <div>
      <common.Header/>
      {
        children
      }
      <common.Footer/>
    </div>
  )
}

export default DesktopLayout
