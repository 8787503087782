import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import appStore from '../../images/appstore.webp'
import playStore from '../../images/googleplay.webp'
import img1 from '../../images/slider1.webp'
import bubble from '../../images/banner_bubble.webp'
import wave from '../../images/banner_wave.webp'
import SmallContent from './SmallContent'
import { useMediaQuery } from 'react-responsive'

const Banner = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 991px)' })
    const text = "Notero - Easy Notes App";
    return (
        <>
            <div className='banner-section'>
                <div className='banner-content'>
                    <Container>
                        <Row className='gx-0'>
                            <Col lg={6}>
                                <div className='banner-info'>
                                    <SmallContent text={text} />
                                    <h1 class="mb-30">Note <span>Your Everything</span> In One App</h1>
                                    <p class="text">No coding required to make customizations. The live customizer{!isMobile && <br/>}has everything you need.</p>
                                    <div className='d-flex align-items-center mt-50' >
                                        <NavLink className=""><img src={appStore} alt="app-store" className='app-store'></img></NavLink>
                                        <NavLink className=""><img src={playStore} alt="play-store"></img></NavLink>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className='banner-img'>
                                    <img src={img1} alt="banner-img"></img>
                                </div>
                            </Col>
                        </Row>
                        <img src={bubble} alt="bubble" className='banner-bubble'></img>
                    </Container>
                    <img src={wave} alt="wave" className='banner-wave'></img>
                </div>
            </div>
        </>
    )
}

export default Banner
