import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import bubble from '../../images/bubble2.webp'
import img1 from '../../images/ipad.webp'
import lines from '../../images/lines.webp'
import CommonHeading from './CommonHeading'
import { FaTag, FaSync, FaTextWidth } from "react-icons/fa";

const LeftImageComponent = () => {
    const text = "Notero - Easy Notes App";
    const para = "Stay focused and productive with a clean and clutter-free note space. The flexible ways to organize your notes: hashtags, nested notebooks, pinning notes to the top of the note list, etc";
    const heading = "The Notero For";
    const headSpan = "Creatives";
    return (
        <div className='left-image-component-section section-content'>
            <Container>
                <Row className='align-items-center justify-content-between'>
                    <Col lg={6}>
                        <div className='mb-30 mb-lg-0'>
                            <img src={img1} alt="left-img"></img>
                        </div>
                    </Col>
                    <Col lg={5}>
                        <div className='left-info'>
                            <CommonHeading text={text} para={para} head={heading} headSpan={headSpan} />
                            <ul>
                                <li className='d-flex align-items-center mb-3'>
                                    <small className='rounded-circle me-3 d-inline-flex align-items-center justify-content-center'><FaTag /></small>
                                    <h6 class="fw-bold">Complete note editor with rich text options</h6>
                                </li>
                                <li className='d-flex align-items-center mb-3'>
                                    <small className='rounded-circle me-3 d-inline-flex align-items-center justify-content-center'><FaSync /></small>
                                    <h6 class="fw-bold">Automatically sync in real times</h6>
                                </li>
                                <li className='d-flex align-items-center mb-3'>
                                    <small className='rounded-circle me-3 d-inline-flex align-items-center justify-content-center'><FaTextWidth /></small>
                                    <h6 class="fw-bold">Complete note editor with rich text options</h6>
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
            <img src={lines} alt="lines" className='left-lines'></img>
            <img src={bubble} alt="bubble" className='left-bubble'></img>
        </div>
    )
}

export default LeftImageComponent
