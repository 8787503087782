import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import CommonHeading from './CommonHeading'
import img1 from '../../images/2mobiles.webp'
import bubble from '../../images/bubble2.webp'
import { FaTag, FaSync, FaTextWidth } from "react-icons/fa";

const RightImageComponent = () => {
    const text = "Better Note Management";
    const para = "Automatically syncs across all your devices. You can also access and write notes without internet connection.";
    const heading = "Your Notes";
    const headSpan = "Security";
    return (
        <div className='right-image-component-section section-content'>
            <Container>
                <Row>
                    <Col lg={5} className='order-2 order-lg-0'>
                        <div className='right-info'>
                            <CommonHeading text={text} para={para} head={heading} headSpan={headSpan} />
                            <ul>
                                <li className='d-flex align-items-center mb-3'>
                                    <small className='rounded-circle me-3 d-inline-flex align-items-center justify-content-center'><FaTag /></small>
                                    <h6 class="fw-bold">Complete note editor with rich text options</h6>
                                </li>
                                <li className='d-flex align-items-center mb-3'>
                                    <small className='rounded-circle me-3 d-inline-flex align-items-center justify-content-center'><FaSync /></small>
                                    <h6 class="fw-bold">Automatically sync in real times</h6>
                                </li>
                                <li className='d-flex align-items-center mb-3'>
                                    <small className='rounded-circle me-3 d-inline-flex align-items-center justify-content-center'><FaTextWidth /></small>
                                    <h6 class="fw-bold">Complete note editor with rich text options</h6>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col lg={1}></Col>
                    <Col lg={6} className='order-0 order-lg-2'>
                        <div className='mb-30 mb-lg-0'>
                            <img src={img1} alt="left-img"></img>
                        </div>
                    </Col>
                </Row>
            </Container>
            <img src={bubble} alt="bubble" className='right-bubble'></img>
        </div>
    )
}

export default RightImageComponent
