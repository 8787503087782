import React from 'react'
import { Container } from 'react-bootstrap'
import CommonHeading from './CommonHeading'
import { NavLink } from 'react-router-dom'
import { FaGithub, FaTwitter, FaTelegram  } from "react-icons/fa";

const Community = () => {
    const text = "Notero Community";
    const para = "";
    const heading = "Join Into";
    const headSpan = "Our Hub";
    return (
        <div className='community-section section-padding pt-0'>
            <Container>
                <div className='text-center'>
                    <CommonHeading text={text} para={para} head={heading} headSpan={headSpan} />
                </div>
                <div className='community-content'>
                    <NavLink className="community-card">
                        <div className='icon'>
                            <FaGithub className='icon-svg' />
                        </div>
                        <div className='content'>
                            <h5>Github</h5>
                            <p>Open Source & Commnit Code</p>
                        </div>
                    </NavLink>
                    <NavLink className="community-card">
                        <div className='icon'>
                            <FaTwitter className='icon-svg' />
                        </div>
                        <div className='content'>
                            <h5>Twitter</h5>
                            <p>Latest News & Update</p>
                        </div>
                    </NavLink>
                    <NavLink className="community-card">
                        <div className='icon'>
                            <FaTelegram className='icon-svg' />
                        </div>
                        <div className='content'>
                            <h5>Telegram</h5>
                            <p>Chanel for Community</p>
                        </div>
                    </NavLink>
                </div>
            </Container>
        </div>
    )
}

export default Community
