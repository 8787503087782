import React from 'react'
import * as common from '../components/common'

const Home = () => {
  return (
    <>
      <common.Banner/>
       <common.Client />
      <common.Features />
     <common.ImageTextComponent />
     <common.Carousal />
        <common.Testimony />
      {/* <common.Pricing />
      <common.Faqs /> */}
      <common.Community />

    </>
  )
}

export default Home
