import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import c1 from '../../images/client1.webp'
import c3 from '../../images/client3.webp'
import c2 from '../../images/client2.webp'
import c4 from '../../images/client4.webp'
import c5 from '../../images/client5.webp'
import c6 from '../../images/client6.webp'
import { useMediaQuery } from 'react-responsive'

const Client = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 991px)' })
    const logoImg = [
        {
           "id":1,
           "img": c1,
           "alt": "client1"
        },
        {
           "id":2,
           "img": c2,
           "alt": "client2"
        },
        {
           "id":3,
           "img": c3,
           "alt": "client3"
        },
        {
           "id":4,
           "img": c4,
           "alt": "client4"
        },
        {
           "id":5,
           "img": c5,
           "alt": "client5"
        },
        {
           "id":6,
           "img": c6,
           "alt": "client6"
        },
    ]
    return (
        <div className='client-section'>
            <Container>
                <div className='text-center'>
                    <h5 className="fw-bold mb-60"><span className="client-logo-text">25K+ Installation</span> and Featured on</h5>
                </div>
                <div className='client-logo-section pb-70'>
                    <Row className='align-items-center'>
                        {logoImg.map((ele, i)=>{
                            return(
                                <Col lg={2} md={6} xs={6} key={i}>
                                    <NavLink className={`client-img ${isMobile ? 'w-100' : ''}`}>
                                        <img src={ele.img} alt={ele.alt}></img>
                                    </NavLink>
                                </Col>
                            )
                        })}
                        <Col>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}

export default Client
