import React from 'react'
import CommonHeading from './CommonHeading'
import { Container } from 'react-bootstrap';
import ing1 from '../../images/integrate1.webp'
import ing2 from '../../images/integrate2.webp'
import ing3 from '../../images/integrate3.webp'
import ing4 from '../../images/integrate4.webp'
import ing5 from '../../images/integrate5.webp'
import ing6 from '../../images/integrate_back.webp'

const Integration = () => {
    const text = "One-Time Payment";
    const para = "Notero intergrate with popular apps. Help you easy to connect and collaboration";
    const heading = "Integration With";
    const headSpan = "Popular Apps";

    const integrateImg = [ing1, ing2, ing4, ing3, ing5];
    return (
        <div className='integration-section pt-60'>
            <div className='text-center'>
                <CommonHeading text={text} para={para} head={heading} headSpan={headSpan} />
            </div>
            <Container>
                <div className='integration-content section-content'>
                    {integrateImg.map((ele, i)=>{
                        return(
                            <div className='integration-img' key={i}>
                                <img src={ele} alt={ele}></img>
                            </div>
                        )
                    })}
                    
                </div>
            </Container>
            <img src={ing6} alt="ing6" class="integrate-back"></img>
        </div>
    )
}

export default Integration
