import React from 'react'
import { useMediaQuery } from 'react-responsive'

const SmallContent = (props) => {
  const isMobile = useMediaQuery({ query: '(max-width: 991px)' })
  return (
    <small className={`${!isMobile ? 'mb-50' : ''} banner-title-small`}> {props.text}</small>
  )
}

export default SmallContent
