import React from 'react'
import wave from '../../images/small_wave.webp'
import LeftImageComponent from './LeftImageComponent'
import RightImageComponent from './RightImageComponent'
import Integration from './Integration'

const ImageTextComponent = () => {
  return (
    <div className='image-text-component-section section-padding'>
      <LeftImageComponent />
      <RightImageComponent />
      <LeftImageComponent />
      <Integration />
      <img src={wave} alt="component-top-wave" className='component-top-wave'></img>
      <img src={wave} alt="component-bottom-wave" className='component-bottom-wave'></img>
    </div>
  )
}

export default ImageTextComponent
