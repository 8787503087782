import React from 'react'
import AppRouters from './routers/AppRouters';

function App() {
  return (
    <AppRouters />
  );
}

export default App;
