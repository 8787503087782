import React from 'react'
import SmallContent from './SmallContent'

const CommonHeading = (props) => {
    return (
        <>
            <div>
                <SmallContent text={props.text} />
                <h2 class="mb-30">{props.head} <span> {props.headSpan} </span> </h2>
            </div>
            <p class="text mb-40">{props.para}</p>
        </>
    )
}

export default CommonHeading
