import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import CommonHeading from './CommonHeading'
import u1 from '../../images/user4.webp'
import u2 from '../../images/user5.webp'
import u3 from '../../images/user6.webp'
import { FaStar } from "react-icons/fa";
import globe from '../../images/globe.webp'

const Testimony = () => {
    const text = "Testimonials";
    const para = "Notero loved from thoudsands customer worldwide and get trusted from big companies.n";
    const heading = "Loved From";
    const headSpan = "Customers";
    const userImage = [
        {
            "rating": 5,
            "img":u1,
            "text":`"You can even send emails to Evernote and gather all of the things you need in a single place."`,
            "para":"jurgen k.",
        },
        {
            "rating": 5,
            "img":u2,
            "text":`"Notero - 1st my choice for notes app. Awesome"`,
            "para":"foden p.",
        },
        {
            "rating": 5,
            "img":u3,
            "text":`"This app is seriously good. It’s simple, clean and a real joy to use."`,
            "para":"Kerry T.",
        }
    ];



    return (
        <div className='testimonial-section pt-70'>
            <Container>
                <div className='testimonial-content'>
                    <Row>
                        <Col lg={5}>
                            <div className='textimonial-head'>
                                <CommonHeading text={text} para={para} head={heading} headSpan={headSpan} />
                            </div>
                        </Col>
                        <Col lg={7}>
                            <div className='testimonial-card'>
                                {userImage.map((ele, i) => {
                                    return (
                                        <div className='user-card' key={i}>
                                            <div className='user-image'>
                                                <img src={ele.img} alt={ele.img}></img>
                                            </div>
                                            <div className='user-content'>
                                                <div className='rating-star mb-2'>
                                                {Array.from({ length: ele.rating }).map((_, ind) => (
                                                    <FaStar key={ind} />
                                                ))}
                                                </div>
                                                <h6>{ele.text}</h6>
                                                <p>{ele.para}</p>
                                            </div>
                                        </div>
                                    )
                                })}
                                <img src={globe} alt="globe" className='globe-img'></img>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}

export default Testimony
